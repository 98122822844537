//import liraries
import React, { Component, Fragment } from "react";
import format from "date-fns/format";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { Link } from "react-router-dom";
import { toastr } from "react-redux-toastr";

import OutletInventory from "./OutletInventory";
import OutletComputers from "./OutletComputers";
import EditName from "./EditFields/EditName";

import EditContact from "./EditFields/EditContact";
import EditLocation from "./EditFields/EditLocation";
import EditOutletType from "./EditFields/EditOutletType";
// import EditStatus from "./EditFields/EditStatus";
import LoadingComponent from "../../../../../../app/layout/LoadingComponent";
import Users from "./Users/index";
import { openModal } from "../../../../../modals/modalActions";
import Tabs from "../../../../../../app/UI/Tabs/Tabs";
import classes from "./Outlet.module.css";

class Outlet extends Component {
  state = {
    initialLoading: true,
    openManageDetailsPanel: false,
    selectedField: null,
    selectedFieldHeader: null,
    outletId: null,
  };

  async componentDidMount() {
    const { firestore, match } = this.props;

    const merchant = await firestore.get(`merchants/${match.params.account}`);
    const outletId = match.params.outletId;

    if (!merchant.exists) {
      toastr.error("Not found", "This is not the merchant you are looking for");
      this.props.history.push("/merchants");
    }

    this.setState({
      initialLoading: false,
      outletId: match.params.outletId,
    });
  }

  componentWillUnmount() {

    const { firestore, match } = this.props;
    firestore.unsetListener(`merchants/${match.params.account}`);
  }

  // openManageUserPanelClasses = [classes.RevealMore];

  handleFieldClick = (selectedField) => {
    if (this.props.profile.userType === "Merchant") {
      return;
    }
    let field;
    switch (selectedField) {
      case "Name":
        field = (
          <EditName
            name={this.props.merchant.name}
            closePanel={this.handleCloseClick}
            account={this.props.merchant.account}
            outletId={this.state.outletId}
          />
        );
        break;
      case "Contact":
        field = (
          <EditContact
            name={this.props.merchant.group}
            closePanel={this.handleCloseClick}
            account={this.props.merchant.account}
            outletId={this.state.outletId}
          />
        );
        break;
      case "Location":
        field = (
          <EditLocation
            name={this.props.merchant.group}
            closePanel={this.handleCloseClick}
            account={this.props.merchant.account}
            outletId={this.state.outletId}
          />
        );
        break;
      case "OutletType":
        field = (
          <EditOutletType
            name={this.props.merchant.group}
            closePanel={this.handleCloseClick}
            account={this.props.merchant.account}
            outletId={this.state.outletId}
          />
        );
        break;
      // case "Status":
      //   field = (
      //     <EditStatus
      //       name={this.props.merchant.group}
      //       closePanel={this.handleCloseClick}
      //       account={this.props.merchant.account}
      //       status={this.props.merchant.isMerchantActive}
      //       outletId={this.props.outletId}
      //     />
      //   );
      //   break;

      default:
        field = null;
    }

    this.setState({
      openManageDetailsPanel: true,
      selectedField: field,
      selectedFieldHeader: selectedField,
    });
  };

  handleCloseClick = () => {
    this.setState({
      openManageDetailsPanel: false,
      selectedField: null,
      selectedFieldHeader: null,
    });
  };

  render() {
    const { openModal, merchant, outlet, auth, profile } = this.props;
    const { openManageDetailsPanel, selectedField, selectedFieldHeader } =
      this.state;
    let merchantName;
    const authenticated = auth.isLoaded && !auth.isEmpty;
    const userRole = profile.role;
    const userType = profile.userType;

    let merchantsLink;
    let selectedMerchantLink;

    if (outlet && merchant) {
      merchantName = merchant.name;

      merchantsLink = `/merchants`;
      selectedMerchantLink = `/merchants/${merchant.account}`;

      return (
        <Fragment>
          <div className={classes.Container}>
            <div className={classes.BreadcrumbWrapper}>
              <div className={classes.Breadcrumb}>
                {/* <span className={classes.Header}><Link to="/merchants">Home</Link>  -  {merchantName}</span> */}
                <span className={classes.Header}>
                  <Link to={merchantsLink}>Home</Link> -
                  <Link to={selectedMerchantLink && selectedMerchantLink}>
                    {merchantName}
                  </Link>
                  - {outlet.name}
                </span>

                <br />
                <span className={classes.ByLine}>
                  You are in the
                  <span className={"bold"}>
                    {merchantName} - {outlet.name}
                  </span>
                  panel
                </span>
              </div>
            </div>

            <div className={classes.Content}>
              <div className={classes.WelcomeContainer}>
                <div className={classes.MerchantProfile}>
                  <div className={"card card-default"}>
                    <div className={"card-body"}>
                      <div className={"row"}>
                        <div className={"col-lg-3"}>
                          <div
                            className={classes.FieldWrapper}
                            onClick={() => this.handleFieldClick("Name")}
                          >
                            <h5
                              className={
                                "all-caps small no-margin hint-text semi-bold"
                              }
                            >
                              Name
                            </h5>
                            <h3 className={"no-margin bold"}>{outlet.name}</h3>
                          </div>
                        </div>
                        <div className={"col-lg-3"}>
                          <div>
                            <h5
                              className={
                                "all-caps small no-margin hint-text semi-bold"
                              }
                            >
                              Outlet ID
                            </h5>
                            <h3 className={"no-margin"}>{outlet.id}</h3>
                          </div>
                        </div>
                        <div className={"col-lg-3"}>
                          <div
                            className={classes.FieldWrapper}
                            onClick={() => this.handleFieldClick("OutletType")}
                          >
                            <h5
                              className={
                                "all-caps small no-margin hint-text semi-bold"
                              }
                            >
                              Type
                            </h5>
                            <h3 className={"no-margin"}>{outlet.type}</h3>
                          </div>
                        </div>
                        <div className={"col-lg-3"}>
                          <div>
                            <h5
                              className={
                                "all-caps small no-margin hint-text semi-bold"
                              }
                            >
                              Date Created
                            </h5>
                            <h3 className={"no-margin"}>
                              {format(
                                outlet.dateCreated &&
                                outlet.dateCreated.toDate(),
                                "YYYY-MM-DD"
                              )}
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className={"row"}>
                        <div className={"card card-transparent"}>
                          <div className={"card-body"}>
                            <Tabs>
                              <div label="Details">
                                <div className={classes.Details}>
                                  <div className={classes.DetailsContent}>
                                    <div className={"row p-t-10"}>
                                      <div className={"col-lg-4"}>
                                        <div
                                          className={classes.FieldWrapper}
                                          onClick={() =>
                                            this.handleFieldClick("Name")
                                          }
                                        >
                                          <label
                                            className={
                                              "control-label all-caps small no-margin hint-text semi-bold"
                                            }
                                          >
                                            Name
                                          </label>
                                          <h5 className={"no-margin"}>
                                            {outlet.name}
                                          </h5>
                                        </div>
                                      </div>
                                      <div className={"col-lg-2"}>
                                        <label
                                          className={
                                            "control-label all-caps small no-margin hint-text semi-bold"
                                          }
                                        >
                                          Outlet ID
                                        </label>
                                        <h5 className={"no-margin"}>
                                          {outlet.id}
                                        </h5>
                                      </div>
                                      <div className={"col-lg-3"}>
                                        <div
                                          className={classes.FieldWrapper}
                                          onClick={() =>
                                            this.handleFieldClick("OutletType")
                                          }
                                        >
                                          <label
                                            className={
                                              "control-label all-caps small no-margin hint-text semi-bold"
                                            }
                                          >
                                            Type
                                          </label>
                                          <h5 className={"no-margin"}>
                                            {outlet.type}
                                          </h5>
                                        </div>
                                      </div>
                                      <div className={"col-lg-3"}>
                                        <label
                                          className={
                                            "control-label all-caps small no-margin hint-text semi-bold"
                                          }
                                        >
                                          Date Created
                                        </label>
                                        <h5 className={"no-margin"}>
                                          {format(
                                            outlet.dateCreated &&
                                            outlet.dateCreated.toDate(),
                                            "YYYY-MM-DD"
                                          )}
                                        </h5>
                                      </div>
                                    </div>

                                    <div className={"row p-t-30"}>
                                      <div className={"col-lg-4"}>
                                        <div
                                          className={classes.FieldWrapper}
                                          onClick={() =>
                                            this.handleFieldClick("Contact")
                                          }
                                        >
                                          <label
                                            className={
                                              "control-label all-caps small no-margin hint-text semi-bold"
                                            }
                                          >
                                            Contact
                                          </label>

                                          <h5 className={"no-margin"}>
                                            {outlet.contact.name}
                                          </h5>
                                          <h5 className={"no-margin"}>
                                            {outlet.contact.email}
                                          </h5>
                                          <h5 className={"no-margin"}>
                                            {outlet.contact.telephone}
                                          </h5>
                                        </div>
                                      </div>

                                      <div className={"col-lg-4"}>
                                        <div
                                          className={classes.FieldWrapper}
                                          onClick={() =>
                                            this.handleFieldClick("Location")
                                          }
                                        >
                                          <label
                                            className={
                                              "control-label all-caps small no-margin hint-text semi-bold"
                                            }
                                          >
                                            Location
                                          </label>
                                          <h5 className={"no-margin"}>
                                            {outlet.suburb}
                                          </h5>
                                          <h5 className={"no-margin"}>
                                            {outlet.city}
                                          </h5>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className={"wizard-footer padding-20 "}
                                    >
                                      <p
                                        className={
                                          "small hint-text pull-left no-margin"
                                        }
                                      />
                                      <div className={"pull-right"} />
                                      <div className={"clearfix"} />
                                    </div>
                                  </div>

                                  {openManageDetailsPanel ? (
                                    <div className={classes.DetailsEdit}>
                                      <div className={classes.EnableUser}>
                                        <div className={classes.EnableUser}>
                                          <div
                                            className={"card card-transparent"}
                                          >
                                            <div
                                              className={"card-header "}
                                              style={{
                                                padding: "0",
                                                minHeight: "4.1rem",
                                                padding: "1rem",
                                                color: "#666",
                                                fontSize: "1.4rem",
                                                fontFamily: "Roboto",
                                                fontWeight: "500",
                                              }}
                                            >
                                              <div
                                                className={
                                                  "card-title semi-bold"
                                                }
                                                style={{
                                                  color: "#666",
                                                  fontSize: "1.4rem",
                                                  fontFamily: "Roboto",
                                                  fontWeight: "500",
                                                  textTransform: "none",
                                                  marginLeft: "0.7rem",
                                                }}
                                              >
                                                Manage Outlet
                                                {selectedFieldHeader}
                                              </div>
                                              <div className={"card-controls"}>
                                                <ul>
                                                  <li>
                                                    <a
                                                      data-toggle="close"
                                                      className={"card-close"}
                                                      href="#"
                                                      onClick={
                                                        this.handleCloseClick
                                                      }
                                                    >
                                                      <i
                                                        className={
                                                          "card-icon card-icon-close"
                                                        }
                                                      />
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                            <div className={"card-body"}>
                                              <div className={"panel"}>
                                                <Fragment>
                                                  {selectedField}
                                                  {/* <ul
                                        className={
                                          "nav nav-tabs nav-tabs-simple d-none d-md-flex d-lg-flex d-xl-flex"
                                        }
                                      >
                                        <li className={"nav-item active"}>
                                          <a
                                            data-toggle="tab"
                                            href="#activation"
                                            className={"active show"}
                                          >
                                            {selectedUser.isEnabled
                                              ? "Disable User"
                                              : "Enable User"}
                                          </a>
                                        </li>
                                        <li className={"nav-item active"}>
                                          <a data-toggle="tab" href="#password">
                                            Change Password
                                          </a>
                                        </li>
                                      </ul>
                                      <div className={"tab-content"}>
                                        <div
                                          className={"tab-pane active"}
                                          id="activation"
                                        >
                                          <div
                                            className={"row column-seperation"}
                                          >
                                            <div className={"col-md-12"}>
                                              {selectedUser.isEnabled ? (
                                                <DisableUser
                                                  user={selectedUser}
                                                  closePanel={
                                                    this.handleCloseClick
                                                  }
                                                />
                                              ) : (
                                                <EnableUser
                                                  user={selectedUser}
                                                  closePanel={
                                                    this.handleCloseClick
                                                  }
                                                />
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className={"tab-pane"}
                                          id="password"
                                        >
                                          <div
                                            className={"row column-seperation"}
                                          >
                                            <div className={"col-md-12"}>
                                              <div>
                                                <ChangeUserPassword
                                                  user={selectedUser}
                                                  closePanel={
                                                    this.handleCloseClick
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div> */}
                                                </Fragment>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div label="Outlet Users">
                                <header className={classes.TabHeader}>
                                  <div className={classes.PageHeader}>
                                    <h4>Outlet Users</h4>
                                  </div>
                                </header>
                                <Users outletId={outlet.id} />
                              </div>
                              <div label="Devices">
                                <header className={classes.TabHeader}>
                                  <div className={classes.PageHeader}>
                                    <h4>Devices</h4>
                                  </div>
                                  {(userRole === "Administrator" ||
                                    userRole === "Supervisor") &&
                                    userType === "PrepaidPlus" && ((this.props.merchant && !this.props.merchant.laybyAmount) || (this.props.merchantBalance && this.props.merchantBalance.balance >= 1950)) && (
                                      <div className={classes.AddUser}>
                                        <button
                                          className={classes.AddUserBtn}
                                          onClick={() =>
                                            openModal(
                                              "RegisterDeviceWithOutletModal",
                                              {
                                                outletId: outlet.id,
                                                account: merchant.account,
                                                userRole: userRole,
                                              }
                                            )
                                          }
                                        >
                                          Assign Devices
                                        </button>
                                      </div>
                                    )}
                                </header>
                                <OutletInventory
                                  merchantName={merchant.name}
                                  group={merchant.group}
                                  outlet={outlet}
                                  userName={profile.displayName}
                                />
                              </div>
                              <div label="Computers">
                                <header className={classes.TabHeader}>
                                  <div className={classes.PageHeader}>
                                    <h4>Computers</h4>
                                  </div>
                                  {userRole === "Administrator" &&
                                    userType === "PrepaidPlus" && (
                                      <div className={classes.AddUser}>
                                        <button
                                          className={classes.AddUserBtn}
                                        onClick={() => {

                                            openModal(
                                              "RegisterMechantComputerModal",
                                              {
                                                account: merchant.id,
                                                outletId: outlet.id,
                                                outletName: outlet.name,
                                                merchantName: merchant.name,
                                              }
                                            );
                                          }}
                                        >
                                          Register Merchant Device
                                        </button>
                                      </div>
                                    )}
                                </header>
                                <OutletComputers
                                  outletId={outlet.id}
                                  account={merchant.id}
                                />
                              </div>
                            </Tabs>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return <LoadingComponent inverted={true} />;
    }
  }
}

const actions = {
  openModal,
};

const mapStateToProps = (state) => ({
  outlet: state.firestore.ordered.outlet && state.firestore.ordered.outlet[0],
  merchant:
    state.firestore.ordered.merchant && state.firestore.ordered.merchant[0],
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  merchantBalance:
    state.firestore.ordered.merchantBalance &&
    state.firestore.ordered.merchantBalance[0],
});

export default compose(
  firestoreConnect((props) => [
    {
      collection: "merchants",
      storeAs: "outlet",
      doc: props.match.params.account,
      subcollections: [
        { collection: "outlets", doc: props.match.params.outletId },
      ],
    },
    {
      collection: "merchant_balance",
      storeAs: "merchantBalance",
      where: [
        ["isActiveBalance", "==", true],
        ["account", "==", props.match.params.account],
      ],
    },
  ]),
  connect(mapStateToProps, actions)
)(Outlet);
