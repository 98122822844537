import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { ReactComponent as Spinner } from "../../assets/spinner.svg";

import {
  combineValidators,
  composeValidators,
  isRequired,
  hasLengthGreaterThan
} from "revalidate";
import styles from "./EnableProvider.module.css";
import { enableProviderAction } from "../merchants/merchantActions";

const customIsRequired = isRequired({ message: "Required" });

const validate = combineValidators({
  password: composeValidators(
    customIsRequired,
    hasLengthGreaterThan(5)({
      message: "Must be 6 characters or longer"
    })
  )()
});

const enableProvider = props => {
  const { provider, enableProviderAction, handleSubmit,loading, closePanel } = props;

  const onFormSubmit = () => {
    enableProviderAction(provider.id);
    closePanel();
  };

  return (
    <div label="Deposits">
      <header className={styles.TabHeader}>
        <div className={styles.PageHeader}>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <h5>
              Type:
              <span className={"semi-bold"}>{provider.type}</span>
            </h5>
            <h5>
              Provider:
              <span className={"semi-bold"}>{provider.provider}</span>
            </h5>

            <div className={"p-t-20 sm-m-t-20 bg-white clearfix"}>
              <ul className={"pager wizard no-style"}>
                <li className="next">


                  <button
                    className={styles.btn}
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <Spinner
                        style={{
                          width: "20px",
                          height: "20px",
                          verticalAlign: "middle",
                        }}
                      />
                    ) : (
                      <span>Enable Provider</span>
                    )}
                  </button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </header>
    </div>
  );
};

const actions = {
  enableProviderAction
};

const mapStateToProps = state => ({loading: state.async.loading,
  enableReinitialize: true
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "enableProviderForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
  })(enableProvider)
);
