import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import moment from "moment";
import Devices from "./Devices/details";
import firebase from "../../../../../../app/config/firebase";
import LoadingComponent from "../../../../../../app/layout/LoadingComponent";
import classes from "./OutletInventory.module.css";
import { formatMoney } from "../../../../../../app/common/util/helpers";
import { toastr } from "react-redux-toastr";

const outletInventory = ({
  outlet_inventory,
  deviceAllocationContracts,
  requesting,
  outlet,
  group,
  merchantName,
  merchantBalance,
  merchants,
  userName
}) => {
  const [load, setLoad] = useState(false);

  const loading = requesting[`outlet_inventory?where=outletId:==:${outlet.id}`];

  const cancelLayby = async (account) => {
    setLoad(true);
    try {
      await firebase.firestore().collection("transactions").doc().set(
        {
          account: account,
          transactionCategory: "Debit",
          transactionType: "Transfer",
          transferToAccount: "2837",
          transferFromAccount: account,
          transferReason: "Layby cancellation fee",
          merchantName: merchantName,
          transferType: "Intra Merchant Transfer",
          dateOfTransfer: new Date(),
          isTransactionCharged: false,
          transactionAmount: parseFloat(295.00),
          transactionDateTime: new Date(),
          transactionStatus: "Successful",
          createdBy: userName,
          dateCreated: new Date(),
        },
      ).catch((e) => {
        console.log("An error occured", e);
      });

      await firebase.firestore().collection("transactions").doc().set(
        {
          account: "2837",
          transactionCategory: "Credit",
          transactionType: "Transfer",
          transferToAccount: "2837",
          transferFromAccount: account,
          transferReason: "Layby cancellation fee",
          merchantName: merchantName,
          transferType: "Intra Merchant Transfer",
          dateOfTransfer: new Date(),
          isTransactionCharged: false,
          transactionAmount: parseFloat(295.00),
          transactionDateTime: new Date(),
          transactionStatus: "Successful",
          createdBy: userName,
          dateCreated: new Date(),
        },
      ).catch((e) => {
        console.log("An error occured", e);
      });

      await firebase.firestore().collection("merchants")
        .doc(account)
        .set(
          {
            laybyAmount: null,
            laybyDate: null,
          },
          {
            merge: true,
          }
        )
        .catch((e) => {
          console.log("An error occured", e);
        });
      toastr.success(`Layby successfully cancelled`);
    } catch (error) {
      toastr.error(`My GOD! Something bad happened`);
    }
    setLoad(false);
  }

  const enrollLayby = async (account, balance) => {
    setLoad(true);
    try {
      if (merchantBalance && merchantBalance.balance >= 585) {
        await firebase.firestore().collection("merchants")
          .doc(account)
          .set(
            {
              laybyAmount: 1950,
              laybyDate: new Date(),
            },
            {
              merge: true,
            }
        ).then(() => {
          toastr.success(`Merchant successfully enrolled on Layby option`);
        })
          .catch((e) => {
            throw new Error(e.message);
          });
      } else {
        throw new Error("Layby requires a minimum deposit of P585.00");
      }

    } catch (e) {
      toastr.error(e.message);
    }

    setLoad(false);
  }

  const getDeviceContract = (deviceId) => {

    let contract = deviceAllocationContracts.find(
      (c) => c.deviceId === deviceId && c.contractStatus !== "Cancelled"
    );

    if (contract) return contract;

    return null;
  };

  let outletInventoryItems =
    outlet_inventory &&
    Object.values(outlet_inventory).map((inventoryItem, index) => {
      return (
        <Devices
          device={inventoryItem}
          deviceContract={
            deviceAllocationContracts &&
            getDeviceContract(inventoryItem.deviceId)
          }
          outlet={outlet}
          group={group}
          merchantName={merchantName}
        />
      );
    });

  return loading ? (
    <Fragment>
      <LoadingComponent inverted={true} />
    </Fragment>
  ) : (
    <Fragment>
      <div className={classes.MerchantTableWrapper}>
        {outlet_inventory && outlet_inventory.length === 0 ? (
            <div style={{ paddingBottom: "30px" }}>
              <div label="Contract">
                <div className={classes.Details}>
                  <div className={classes.DetailsContent}>
                    {merchants && merchants.laybyAmount && (<>
                      <div className={"row p-t-10"}>
                        <div className={"col-lg-4"}>
                          <label
                            className={
                              "control-label all-caps small no-margin hint-text semi-bold"
                            }
                          >
                            Layby Status
                          </label>
                          <h5 className={"no-margin"}>
                            {merchantBalance && merchantBalance.balance >= 1950 ? "Completed" : "Active"}
                          </h5>
                        </div>
                        <div className={"col-lg-4"}>
                          <div>
                            <label
                              className={
                                "control-label all-caps small no-margin hint-text semi-bold"
                              }
                            >
                              Enrollment Date
                            </label>
                            <h5 className={"no-margin"}>
                              {moment(merchants.laybyDate.seconds, 'X').format('YYYY-MM-DD')}
                            </h5>
                          </div>
                        </div>
                        <div className={"col-lg-4"}>
                          <label
                            className={
                              "control-label all-caps small no-margin hint-text semi-bold"
                            }
                          >
                            Contract Amount
                          </label>
                          <h5 className={"no-margin"}>
                            P{formatMoney(merchants.laybyAmount)}
                          </h5>
                        </div>
                      </div>

                      <div className={"row p-t-10"}>
                        <div className={"col-lg-4"}>
                          <label
                            className={
                              "control-label all-caps small no-margin hint-text semi-bold"
                            }
                          >
                            Amount Paid(including Deposit)
                          </label>
                          <h5 className={"no-margin"}>
                            P{merchantBalance && merchantBalance.balance > merchants.laybyAmount ? formatMoney(merchants.laybyAmount) : merchantBalance && formatMoney(merchantBalance.balance)}
                          </h5>
                        </div>
                        <div className={"col-lg-4"}>
                          <div>
                            <label
                              className={
                                "control-label all-caps small no-margin hint-text semi-bold"
                              }
                            >
                              Amount Outstanding
                            </label>
                            <h5 className={"no-margin"}>
                              P{merchantBalance && merchants.laybyAmount - merchantBalance.balance < 0 ? formatMoney(0) : merchantBalance && formatMoney(merchants.laybyAmount - merchantBalance.balance)}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </>)}
                    {merchants && !merchants.laybyAmount && (<div className={"wizard-footer p-t-30"}>
                      <button
                        disabled={load}
                        className={`${classes.AddNewMerchantBtn} m-r-30`}
                        onClick={() =>
                          enrollLayby(merchants.account)
                        }
                      >
                        {load ? "⏳" : <>"Enroll on Layby"
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              style={{
                                height: "24px",
                                width: "24px",
                                verticalAlign: "bottom",
                              }}
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                              />
                            </svg>
                          </span></>}
                      </button>
                      <div className={"pull-right"} />
                      <div className={"clearfix"} />
                    </div>)}
                    {merchants && merchants.laybyAmount && (<div className={"wizard-footer p-t-30"}>
                      <button
                        disabled={load}
                        className={`${classes.AddNewMerchantBtnRed} m-r-30`}
                        onClick={() =>
                          cancelLayby(merchants.account)
                        }
                      >
                        {load ? "⏳" : <>"Cancel Layby"
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              style={{
                                height: "24px",
                                width: "24px",
                                verticalAlign: "bottom",
                              }}
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                              />
                            </svg>
                          </span></>}
                      </button>
                      <div className={"pull-right"} />
                      <div className={"clearfix"} />
                    </div>)}
                  </div>
                </div>
              </div>
          </div>
        ) : (
          <Fragment>
            <div className={classes.TableWrapper}>
              <table className={classes.MerchantTable}>
                <thead>
                  <tr>
                    <th scope="col">Model</th>
                    <th className={"hide-s"} scope="col">
                      Network
                    </th>
                    <th className={"hide-s"} scope="col">
                      Cell Number
                    </th>
                    <th className={"hide-s"} scope="col">
                      Is Enabled
                    </th>
                    <th className={"hide-s"} scope="col">
                      Delivery Status
                    </th>
                  </tr>
                </thead>
                <tbody>{outletInventoryItems}</tbody>
              </table>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

const query = (props) => {
  console.log("props", props);
  return [
    {
      collection: "outlet_inventory",
      where: ["outletId", "==", props.outlet.id],
    },
    {
      collection: "deviceAllocationContracts",
      where: ["outletId", "==", props.outlet.id],
    },
    {
      collection: "merchant_balance",
      storeAs: "merchantBalance",
      where: [
        ["isActiveBalance", "==", true],
        ["account", "==", props.outlet.id.toString().slice(0, -3)],
      ],
    },
    {
      collection: "merchants",
      storeAs: "merchants",
      where: [
        ["account", "==", props.outlet.id.toString().slice(0, -3)],
      ],
    },
  ];
};

const mapStateToProps = (state) => ({
  outlet_inventory: state.firestore.ordered.outlet_inventory,
  deviceAllocationContracts: state.firestore.ordered.deviceAllocationContracts,
  profile: state.firebase.profile,
  requesting: state.firestore.status.requesting,
  merchantBalance:
    state.firestore.ordered.merchantBalance &&
    state.firestore.ordered.merchantBalance[0],
  merchants:
    state.firestore.ordered.merchants &&
    state.firestore.ordered.merchants[0],
});

export default connect(mapStateToProps)(
  firestoreConnect(query)(outletInventory)
);
