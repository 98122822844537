import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { ReactComponent as Spinner } from "./../../../assets/spinner.svg";

import {
  combineValidators,
  composeValidators,
  isRequired,
  hasLengthGreaterThan,
} from "revalidate";
import {
  enableProviders,
  disableProviders,
} from "./../../merchants/merchantActions.jsx";
import styles from "./EnableProvider.module.css";

const customIsRequired = isRequired({ message: "Required" });

const validate = combineValidators({
  password: composeValidators(
    customIsRequired,
    hasLengthGreaterThan(5)({
      message: "Must be 6 characters or longer",
    })
  )(),
});

const enableProvider = (props) => {
  const {
    provider,
    enableProviders,
    disableProviders,
    handleSubmit,
    merchant,
    loading,
    closePanel,
  } = props;

  const onFormSubmit = async (values) => {
    if (buttonState.state === "enable") {
      await enableProviders(provider, merchant.account);
      buttonState.state = undefined;
    } else {
      await disableProviders(provider, merchant.account);
      buttonState.state = undefined;
    }
    closePanel();
  };

  const buttonState = { state: undefined };
  return (
    <div label="Deposits">
      <header className={styles.TabHeader}>
        <div className={styles.PageHeader}>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <h5>
              Type:
              <span className={"semi-bold"}>{provider.type}</span>
            </h5>
            <h5>
              Provider:
              <span className={"semi-bold"}>{provider.provider}</span>
            </h5>

            <div className={"p-t-20 sm-m-t-20 bg-white clearfix"}>
              <ul className={"pager wizard no-style"}>
                <li className="next">
                  <div className="row">
                    <button
                      className={"btn btn-primary btn-cons m-t-10"}
                      onClick={() => {
                        buttonState.state = "enable";
                      }}
                      type="submit"
                      disabled={loading}
                    >
                      {loading && buttonState.state == "enable" ? (
                        <Spinner
                          style={{
                            width: "20px",
                            height: "20px",
                            verticalAlign: "middle",
                          }}
                        />
                      ) : (
                        <span>Enable Provider</span>
                      )}
                    </button>
                    <button
                      className={"btn btn-danger btn-cons m-t-10"}
                      type="submit"
                      onClick={() => {
                        buttonState.state = "disable";
                      }}
                      disabled={loading}
                    >
                      {loading && buttonState.state == "disable" ? (
                        <Spinner
                          style={{
                            width: "20px",
                            height: "20px",
                            verticalAlign: "middle",
                          }}
                        />
                      ) : (
                        <span>Disable Provider</span>
                      )}
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </header>
    </div>
  );
};

const actions = {
  enableProviders,
  disableProviders,
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  merchant: state.firestore.ordered.merchant[0],
  enableReinitialize: true,
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "enableProviderForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
  })(enableProvider)
);