import React, { Component } from "react";
import Modal from "../../app/UI/Modal/Modal";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import EnableProviders from "../providers/EnableProviders/BulkEnableProvider";

const actions = { closeModal };

const enableProviderModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="Enable Providers" onClose={closeModal}>
      <EnableProviders />
    </Modal>
  );
};

export default connect(null, actions)(enableProviderModal);